<template>
  <div class="positive detail-wrapper" v-if="detailData">
    <div class="main-content">
      <div class="diagram-content">
        <el-tabs type="card" class="diagram-content_tab" v-model="activeTab">
          <el-tab-pane label="当前流程" name="current" class="diagram-content_current">
            <positive-form-info :data="detailData"></positive-form-info>
            <el-divider class="line-dividing"><span>流程图</span></el-divider>
            <detail-flow :flows="currentActivity" :data-status="detailData.status"></detail-flow>
          </el-tab-pane>
          <el-tab-pane label="历史记录" name="history" v-if="showHistory && !(from==='copyToMe'||from==='approval')" class="diagram-content_history">
            <template v-if="historyRecord&&historyRecord.length">
              <div class="record-item" v-for="record in historyRecord">
                <positive-form-info :data="record"></positive-form-info>
                <el-divider class="line-dividing"><span>流程图</span></el-divider>
                <detail-flow :flows="[record.activityModel.activitiNodeDetails]" :data-status="record.status"></detail-flow>
              </div>
            </template>
            <template v-else>
              <div class="no-record-data">暂无数据</div>
            </template>

          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="occupy-ele"></div>
    <div class="button-group fixed-bottom-btn">
      <el-row>
        <el-button class="btn-default" size="medium" @click="back">返回</el-button>
        <template v-if="taskId">
          <el-button class="btn-primary mgl20" size="medium"  type="danger" @click="reject">驳回</el-button>
          <el-button class="bbtn-submit mgl20" size="medium"  type="primary" @click="pass">通过</el-button>
        </template>
      </el-row>
    </div>

    <el-dialog class="dialog-wrapper" title="驳回" :visible.sync="showRejectDialog" width="560px">
      <el-form :model="rejectObj" class="operateForm" :rules="formRules"  ref="rejectForm">
        <el-form-item label="理由" prop="remark">
          <el-input type="textarea" rows="3" v-model="rejectObj.remark" maxlength="80" class="form-group_input"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showRejectDialog=false" size="medium">取消</el-button>
        <el-button type="primary" @click="rejectSubmit" size="medium">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog class="dialog-wrapper" title="通过" :visible.sync="showPassDialog" width="560px">
      <el-form :model="passObj" class="operateForm">
        <el-form-item label="审核意见">
          <el-input type="textarea" rows="3" v-model="passObj.remark" maxlength="80" class="form-group_input"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showPassDialog=false" size="medium">取消</el-button>
        <el-button type="primary" @click="auditSubmit" size="medium">确定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import DetailFlow from "../../../../components/detail-flow";
import PositiveFormInfo from "./components/positive-form-info";
export default {
  name: "positive-detail",
  components: {PositiveFormInfo, DetailFlow},
  props:{
    taskId:[Number, String],
    id:[Number, String],
    from:String,
    showHistory: {
      type:Boolean,
      default(){
        return true
      }
    }
  },
  data(){
    return {
      urlId:0,
      detailData:{},
      historyRecord:[],
      currentActivity:[],
      showRejectDialog:false,
      showPassDialog:false,
      rejectObj:{},
      passObj:{},
      activeTab:'current',
      formRules:{
        remark:this.$formValidator.empty("驳回理由")
      },
      typeId:this.$store.state.PERSONNEL.POSITIVE_TYPEID,
    }
  },
  created() {
    this.urlId = this.$route.params.id;
    if(this.urlId||this.id){
      this.getDetail(this.urlId||this.id);
      // this.getHistoryActivity(this.urlId||this.id);
    }else{
      this.getWaitDetail(this.taskId)
    }
  },
  methods:{
    //  根据ID获取详情
    getDetail(id){
      this.$api.personnel.getPositiveDataDetail({id:id}).then((res) => {
        this.detailData = res.data;
        this.currentActivity = [res.data.activityModel.activitiNodeDetails];
        if(detailData.hasHistory){
          this.getHistoryActivity(this.urlId||this.id);
        }
      })
    },
    getHistoryActivity(id){
      this.$api.personnel.getPositiveHisFlow({id:id}).then(response=>{
        if(response.code===200){
          this.historyRecord = response.data.list;
        }
      })
    },

    // 获取待办详情
    getWaitDetail(taskId){
      this.$api.personnel.getPositiveWaitDetail({taskId:taskId}).then(res=>{
        this.detailData = res.data;
        this.currentActivity = [res.data.activityModel.activitiNodeDetails];
        this.getHistoryActivity(res.data.id)
      })
    },

    getNameById(target,prop,value){
     let result = target.filter(itr=>itr&&itr[prop]==value)
     return result&&result.length?result[0]:''
    },

    reject(){
      this.showRejectDialog = true;
      this.rejectObj = {}
    },

    rejectSubmit(){
      this.$refs.rejectForm.validate((valid)=>{
        if(valid){
          let param = {
            id:this.detailData.id,
            reason:this.rejectObj.remark?this.rejectObj.remark.trim():'',
            taskId:this.taskId,
            typeId:this.$store.state.PERSONNEL.POSITIVE_TYPEID
          }
          this.$api.checkapi.rejectMyAppply(param).then(res=>{
            this.showRejectDialog = false;
            this.back()
          })
        }
      })

    },

    pass(){
      this.showPassDialog = true;
      this.passObj = {}
    },

    auditSubmit(){
      let param = {
        id:this.detailData.id,
        remark:this.passObj.remark?this.passObj.remark.trim():'',
        taskId:this.taskId,
        typeId:this.$store.state.PERSONNEL.POSITIVE_TYPEID
      }
      this.$api.checkapi.passMyAppply(param).then(res=>{
        this.showPassDialog = false;
        this.back()
      })

    },
    // 返回
    back(){
      if(this.urlId){ // 来自劳动合同列表
        this.$router.go(-1)
      }else{ // 来自审核列表
        this.$emit('changeType', true)
      }
    }
  },

}
</script>

<style scoped lang="scss">
@import "../common-style/detail-page";
</style>